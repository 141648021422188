import React from "react";
import Project from "./Project";
import "./style.scss";

function Portfolio() {
  return (
    <div>
      <main className="col-9 mt-5 pt-2">
          <Project />
      </main>
    </div>
  );
}

export default Portfolio;
