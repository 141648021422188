import React from "react";
import "./style.scss";

function Resume() {
  return (
    <section className="resume infoBox col-11 col-sm-4 mx-auto p-4 my-4">
      <h3>For more information...</h3>
      <p>check out my resume.</p>
    </section>
  );
}

export default Resume;
